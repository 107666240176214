<template>
  <div>
    <keep-alive>
      <m-gmaps ref="map" class="dashboard-discovery-map" :markers="markers" :marker-details="markerDetails" />
    </keep-alive>
    <q-btn
      icon="far fa-location"
      class="locationChangeBtn q-px-xs"
      :label="$t('change_map_location')"
      no-caps
      dense
      rounded
    >
      <q-menu :offset="[0, 8]" class="transparent">
        <m-select-query
          v-model="newLocationQuery"
          :placeholder="$t('search_location')"
          icon="fas fa-location"
          color="primary-tint"
          bg-color="white"
          class="q-ma-xs"
          dense
          :change-location="true"
          :query="locationAutocomplete"
        />
      </q-menu>
    </q-btn>
    <q-btn
      fab
      color="white"
      icon="fas fa-plus"
      class="zoomInBtn"
      @click="() => setZoom(+1)"
    />
    <q-btn
      fab
      color="white"
      icon="fas fa-minus"
      class="zoomOutBtn"
      @click="() => setZoom(-1)"
    />
    <q-btn
      icon="far fa-location"
      class="recenterBtn"
      color="white"
      text-color="primary"
      fab
      @click="setMapToCurrentLocation"
    />

    <m-mobility-nearby-cards v-if="(nearbyItems || loading)" />
    <!-- <m-mobility-nearby-list v-else-if="nearbyItems || loading" /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { query as placeQuery, details as placeDetails } from 'api/places'
import authentication from 'mixins/authentication'
import travelContents from 'mixins/travelContents'
import { MGmaps, MSelectQuery, MMobilityNearbyCards } from 'components'

export default {
  name: 'Dashboard',
  components: { MGmaps, MSelectQuery, MMobilityNearbyCards },
  mixins: [ authentication, travelContents ],
  data () {
    return {
      markers: [],
      markerDetails: {}
    }
  },
  computed: {
    ...mapGetters({
      geoip: 'geoip',
      latLng: 'geolocate/latLng',
      nearbyItems: 'discovery/items',
      loading: 'discovery/loading',
      mobility: 'partner/mobility',
      bookables: 'partner/bookables',
      searchLocation: 'map/searchLocation'
    }),
    newLocationQuery: {
      get () {
        return null
      },
      set (val) {
        placeDetails(val.value).then(res => {
          const marker = val.context ? 'https://api.staging.mobilleo.net/icon/marker/w3w.svg?width=24&height=36&color=%23e11f26' : require('assets/markers/map-marker@0.75x.png')
          this.setMapToGeolocation(res.data.geometry.location, marker)
          if (this.nearbyItems) {
            this.$store.dispatch('discovery/searchNearby', { fresh: true })
          }
        })
      }
    },
    mobilityAndBookablesCombined () {
      return [...this.mobility, ...this.bookables]
    }
  },
  watch: {
    nearbyItems (newVal) {
      this.markers = []
      if (newVal && newVal.length) {
        // If we've set a search location, put the marker back on the map
        if (this.searchLocation.latitude) {
          const { latitude, longitude } = this.searchLocation
          this.markers.push({
            position: { lat: latitude, lng: longitude },
            icon: {
              url: require('assets/markers/map-marker@0.75x.png')
            },
            class: 'marker'
          })
        }
        let mobilityType = this.mobilityAndBookablesCombined.find(x => this.getTravelPreferenceName(x) === newVal[0].type)
        this.markerDetails = { icon: this.getMapIcon(mobilityType), color: this.getTravelContentHex(mobilityType) }
        newVal.forEach((item, index) => {
          this.markers.push({
            key: `${item.name}-${index}`,
            position: { lat: item.latitude, lng: item.longitude },
            class: 'marker',
            color: this.getTravelContentHex(mobilityType),
            iconName: this.getMapIcon(mobilityType)
          })
        })
      }
    }
  },
  methods: {
    setMapToGeolocation ({ lat, lng }, marker) {
      let useLat = this.latLng.latitude || this.geoip.latitude
      let useLng = this.latLng.longitude || this.geoip.longitude
      this.markers = []
      if (lat && lng) {
        useLat = lat
        useLng = lng
        this.markers.push({
          position: { lat: useLat, lng: useLng },
          icon: {
            url: marker
          },
          class: 'marker'
        })
      }
      this.$store.dispatch('map/setCenter', { lat: useLat, lng: useLng })
      this.$store.dispatch('map/setSearchLocation', { latitude: useLat, longitude: useLng })
      this.$nextTick(() => {
        this.$root.$emit('setMap', (map) => {
          map.panTo({
            lat: useLat,
            lng: useLng
          })
          map.setZoom(13)
        })
      })
    },
    setMapToCurrentLocation () {
      // Explicit method for recentering, so we can also re-do the search
      this.setMapToGeolocation({ lat: this.geoip.latitude, lng: this.geoip.longitude })
      // Yes, I know setMapToGeolocation defaults to geoip, but just in case that function is ever updated... I thought I'd specifiy specifically.
      this.$store.dispatch('discovery/searchNearby', { fresh: true })
    },
    setZoom (val) {
      this.$root.$emit('setMap', map => {
        const zoom = map.zoom
        map.setZoom(zoom + val)
      })
    },
    locationAutocomplete (terms) {
      return placeQuery(terms)
        .then(response => {
          return response.data.map(result => {
            return {
              label: result.description,
              value: result.place_id,
              icon: 'place'
            }
          })
        })
    }
  }
}
</script>

<style lang="stylus">
  .dashboard-discovery-map
    position fixed
    width 100%
    height calc(100vh - 64px)
    z-index 1
  .locationChangeBtn
    position absolute
    z-index 10
    left 24px
    top 24px
    font-size .75em
    background-color white
    color var(--q-color-primary)
  .recenterBtn
    position absolute
    z-index 10
    right 24px
    bottom 96px
    bottom 24px
    background-color white
    color var(--q-color-primary)
  .zoomInBtn
    position absolute
    z-index 10
    left 24px
    bottom 96px
  .zoomOutBtn
    position absolute
    z-index 10
    left 24px
    bottom 24px

  @media only screen and (max-width: 936px)
    .recenterBtn
      bottom 324px
</style>
